// This file is auto generated by data-scripts/_helpers/runtime.ts
import boysFirstnames from './boysFirstnames.json'
import commonWords from './commonWords.json'
import girlsFirstnames from './girlsFirstnames.json'
import lastnames from './lastnames.json'
import wikipedia from './wikipedia.json'
import translations from './translations'

const dictionary = {
  'boysFirstnames-nl-be': boysFirstnames,
  'commonWords-nl-be': commonWords,
  'girlsFirstnames-nl-be': girlsFirstnames,
  'lastnames-nl-be': lastnames,
  'wikipedia-nl-be': wikipedia,
}

export { dictionary, translations }
