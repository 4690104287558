import { Injectable } from '@angular/core';

import { ZxcvbnFactory, ZxcvbnResult } from 'zxcvbn-core';
import * as zxcvbnCommon from 'zxcvbn-common';
import * as zxcvbnEn from 'zxcvbn-language-en';
import * as zxcvbnNlBe from 'zxcvbn-language-nl-be';

@Injectable({
  providedIn: 'root'
})
export class ZxcvbnService {
  private zxcvbn: ZxcvbnFactory

  constructor() {
    this.zxcvbn = new ZxcvbnFactory({
      translations: zxcvbnNlBe.translations,
      graphs: zxcvbnCommon.adjacencyGraphs,
      dictionary: {
        ...zxcvbnCommon.dictionary,
        ...zxcvbnEn.dictionary,
        ...zxcvbnNlBe.dictionary,
      }
    })
  }

  public check(password: string): ZxcvbnResult {
    return this.zxcvbn.check(password)
  }
}
